const baseUrl = "https://apigw.fermi.rid-intrasoft.eu";
// https://128.140.64.58:9080

const getOptions = (baseUrl, endPoint = "", parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
      "Content-Type": "application/json",
    },
  };
};

const putOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
      "Content-Type": "application/json",
    },
    data: data,
  };
};

const postOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : JSON.parse(sessionStorage.getItem("kc_action")).token
      }`,
      "Content-Type": "application/json",
    },
    data: data,
  };
};

const deleteOptions = (baseUrl, endPoint = "") => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
      "Content-Type": "application/json",
    },
  };
};

export { baseUrl, getOptions, putOptions, postOptions, deleteOptions };
