import "../../../styles/Loader.scss";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function Loader() {
  return (
    <div id="loader-container">
      <CircularProgress />
    </div>
  );
}

export default Loader;
