import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  MarkerType,
  Controls,
  MiniMap,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import CircularNode from "./CircularNode";
import "../../styles/NodeGraph.scss";
import { Button } from "@mui/material";
import ColorsTable from "./NodeGraphDetails/ColorsTable";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 150;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const getNodes = (nodes) => {
  let newNodes = [];
  nodes.forEach((node) => {
    newNodes.push({
      id: node.id,
      data: {
        label: (
          <div className="title">
            {node.properties.botClassify === 1 ? (
              <>
                Human <br /> Account
              </>
            ) : node.properties.botClassify === 0 ? (
              <>
                Bot <br /> Account
              </>
            ) : (
              <>
                Unknown <br /> Account Type
              </>
            )}
          </div>
        ),
        info: node,
      },
      connectable: false,
      draggable: true,
      type: "circularNode",
      className: `${
        node.properties.botClassify === 0
          ? "non-bot-node"
          : node.properties.botClassify === 1
          ? "bot-node"
          : ""
      }`,
    });
  });

  return newNodes;
};

const getEdges = (edges) => {
  let newEdges = [];
  edges.forEach((edge) => {
    let strokeColor = "";
    strokeColor =
      edge.label === "REPLY_TO"
        ? "#E29650"
        : edge.label === "RETWEETS"
        ? "#5653DF"
        : edge.label === "QUOTED"
        ? "#86CB67"
        : "black";
    newEdges.push({
      id: `e${edge.start.id}-${edge.end.id}`,
      source: edge.start.id,
      target: edge.end.id,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: strokeColor,
      },
      label: edge.label,
      style: { stroke: strokeColor, strokeWidth: 3 },
    });
  });

  return newEdges;
};

const nodeTypes = { circularNode: CircularNode };

function NodeGraph({ nodeData }) {
  const [layoutedNodes, setLayoutedNodes] = useState([]);
  const [layoutedEdges, setLayoutedEdges] = useState([]);

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [key, setKey] = useState(0); // key is used as a prop to NodeGraph so it can be re-rendered and recentered every time node data changes
  // const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    let { nodes: layoutedNodesResult, edges: layoutedEdgesResult } =
      getLayoutedElements(getNodes(nodeData.nodes), getEdges(nodeData.rels));
    setLayoutedNodes(layoutedNodesResult);
    setLayoutedEdges(layoutedEdgesResult);
    setNodes([...layoutedNodesResult]);
    setEdges([...layoutedEdgesResult]);
    setKey((prevKey) => prevKey + 1);
  }, [nodeData]); //eslint-disable-line react-hooks/exhaustive-deps

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );
  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges] //eslint-disable-line react-hooks/exhaustive-deps
  );

  // const onNodeClick = useCallback((event, node) => {
  //   // You can perform any action here when a node is clicked
  //   setSelectedNode(node);
  // }, []);

  const colors = [
    { colorCode: "#5653DF", details: "Retweets Edge" },
    { colorCode: "#86CB67", details: "Quoted Edge" },
    { colorCode: "#E29650", details: "Reply To Edge" },
    { colorCode: "#F9C858", details: "Unknown node/edge" },
  ];

  return (
    <div id="node-graph-container">
      <h3>Disinformation Sources & Spread Analysis</h3>
      <ColorsTable colors={colors} />
      <div className="content-container">
        <div className="node-graph-wrapper">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            key={key}
            // onNodeClick={onNodeClick}
          >
            <Panel position="top-right">
              <div id="layout-buttons">
                <Button variant="contained" onClick={() => onLayout("TB")}>
                  Vertical Layout
                </Button>
                <Button variant="contained" onClick={() => onLayout("LR")}>
                  Horizontal Layout
                </Button>
              </div>
            </Panel>
            <Controls />
            <MiniMap
              nodeStrokeColor={(n) => "#fff"}
              nodeColor={(n) => "#aaa"}
            />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default NodeGraph;
