import React from "react";
import CustomTooltip from "../CustomTooltip";
import CountryLink from "../CountryLink";

function TabsContent({ country }) {
  return (
    <div id="tabs-content">
      {country === "finland" ? (
        <FinlandProfile />
      ) : country === "sweden" ? (
        <SwedenProfile />
      ) : country === "france" ? (
        <FranceProfile />
      ) : country === "belgium" ? (
        <BelgiumProfile />
      ) : country === "germany" ? (
        <GermanyProfile />
      ) : null}
    </div>
  );
}

export default TabsContent;

const FinlandProfile = () => {
  return (
    <div className="profile">
      <h3 id="finland" className="section-title">
        Republic of Finland:{" "}
        <CustomTooltip title="Political System: Finland is a parliamentary republic with a head of government (the prime minister) and a head of state (the president). Sauli Niinisto won the presidential election in 2012 and became the country's first conservative head of state in five decades, he won a second term in the January 2018 election. Conservative leader Petteri Orpo won a close three-way election in April 2023, defeating the centre-left incumbent Sanna Marin. He leads a coalition of the conservative NCP, nationalist Finns, the minority-language Swedish People's Party and the Christian Democrat. Finland scores consistently well on international ratings for stability, freedom, public safety and social progress. Its parliament was the first to adopt full gender equality, granting men and women the right not only to vote but also to stand for election in 1906.">
          <span>
            Facts<sup>(1)</sup>
          </span>
        </CustomTooltip>
      </h3>
      <ul>
        <li>Capital: Helsinki</li>
        <li>Area: 338,455 sq km</li>
        <li>Population: 5.6 million</li>
        <li>Languages: Finnish, Swedish plus Sami, Karelian, Finnish Kalo</li>
      </ul>

      <h4>Media & Media Literacy in Finland</h4>

      <p>
        TV is the most popular medium in Finland however, news media are
        diverse. The public broadcaster Yle holds almost half of both TV and
        radio audiences, there are some privately owned domestic broadcasters,
        as well as, an increasing number of online news outlets, and several
        regional and local newspapers. Most media outlets are totally
        independent of political parties and politicians, as aforementioned,
        state-owned Yle is the only exception, nonetheless, politicians play no
        role in appointing or dismissing its journalists and reported attempts
        by politicians to influence media content are rare and generally not
        tolerated. Finland is the leading EU country in terms of press
        readership, and 58% of Finns say they read a press title{" "}
        <CustomTooltip
          title={
            <div>
              European Union, Finland – EU member country profile | European
              Union. Available at:{" "}
              <CountryLink
                link={
                  "https://european-union.europa.eu/principles-countries-history/country-profiles/finland_en"
                }
              />
              <br />
              RSF – Reporters without borders, Finland. Available at:{" "}
              <CountryLink link={"https://rsf.org/en/country/finland"} />
            </div>
          }
        >
          <span>
            daily.<sup>(2, 3)</sup>
          </span>
        </CustomTooltip>{" "}
        The state of media freedom is rated highly by international press
        freedom{" "}
        <CustomTooltip
          title={
            <div>
              European Union, Finland – EU member country profile | European
              Union. Available at:
              <CountryLink
                link={
                  "https://european-union.europa.eu/principles-countries-history/country-profiles/finland_en"
                }
              />
            </div>
          }
        >
          <span>
            organisations<sup>(4)</sup>
          </span>
        </CustomTooltip>{" "}
        and more specifically Finland ranked fifth, after Sweden, in the 2023
        Reporters Without Borders (RSF) global media freedom{" "}
        <CustomTooltip
          title={
            <div>
              RSF - Reporters without borders, Press freedom index - 2023.
              Available at:{" "}
              <CountryLink link={"https://rsf.org/en/index?year=2023"} />
            </div>
          }
        >
          <span>
            index.<sup>(5)</sup>
          </span>
        </CustomTooltip>
      </p>

      <h4>Main findings Finland</h4>

      <p>
        Finland holds a comparatively high media literacy level and this is
        likely a reflection of their approach in education and their efforts in
        endorsing media freedom. Finland has been ranked as a world leader in
        education for various reasons e.g., promoting critical thinking above
        tests, common-sense practices and a holistic teaching environment that
        strives for equity over{" "}
        <CustomTooltip
          title={
            <div>
              Center for an Informed Public - University of Washington, What we
              can learn from Finland, March 01, 2023. Available at:
              <CountryLink
                link={
                  "https://www.cip.uw.edu/2023/03/01/finland-media-literacy/"
                }
              />
              <br />
              Mike Colagrossi, 10 reasons why Finland's education system is the
              best in the world, World Economic Forum, Sept. 10 2018. Available
              at:{" "}
              <CountryLink
                link={
                  "https://www.weforum.org/agenda/2018/09/10-reasons-why-finlands-education-system-is-the-best-in-the-world"
                }
              />
            </div>
          }
        >
          <span>
            excellence.<sup>(6,7)</sup>
          </span>
        </CustomTooltip>{" "}
        Finland ranks at the top of 41 European countries in their resilience to
        false{" "}
        <CustomTooltip
          title={
            <div>
              Marin Lessenski, How It Started, How It is Going: Media Literacy
              Index 2022, Policy Brief 57, October 2022, Open Society Institute
              – Sofia. Available at:{" "}
              <CountryLink link={"https://osis.bg/?p=4243&lang=en"} />
              <br />
              Мarin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute – Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf."
                }
              />
            </div>
          }
        >
          <span>
            allegations<sup>(8,9)</sup>
          </span>
        </CustomTooltip>{" "}
        , critical thinking and media literacy skills in Finland are initiated
        already at preschool across all teaching subjects.
      </p>
      <p>
        According to the European Media Literacy Index (MLI) Report for{" "}
        <CustomTooltip
          title={
            <div>
              Мarin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute – Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf."
                }
              />
            </div>
          }
        >
          <span>
            2023<sup>(10)</sup>
          </span>
        </CustomTooltip>{" "}
        , Finland is placed 1st out of 41 countries in total in the respective
        ranking, with a score of 74 points out of 100. Finland is included in
        the 1st cluster of “best-performing countries”, Finland is followed by
        Denmark, Norway, Estonia, Sweden and Ireland.
      </p>
      <p>
        Finland’s comprehensive approach has become the go-to example for many
        governments and supranational bodies for addressing{" "}
        <CustomTooltip
          title={
            <div>
              Мarin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute – Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf."
                }
              />{" "}
              p.3
            </div>
          }
        >
          <span>
            D&FN.<sup>(11)</sup>
          </span>
        </CustomTooltip>{" "}
        Evidence suggests that Finland is really invested in media literacy and
        the problem of fake news and disinformation, it has taken various
        measures and initiatives in preparing and educating citizens of all ages
        for the complex digital landscape of the present and the future.
      </p>
      <p>
        From the data presented in the document for the Country Profiles some
        key takeaways are: online
      </p>
      <ul>
        <li>
          <strong>In terms of daily media consumption</strong>, news on the
          internet remain predominant (70%), and is followed by TV (67%), use of
          social networks (60%), and almost equally by the written press (51%)
          and the radio (50%). Interestingly, 18% of the population has no
          access to social{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ‚Standard Eurobarometer STD96: Standard
                Eurobarometer 96 – Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              networks.<sup>(12)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Ages 15-24 use online social networks at a striking percentage
              (100%) at least once a week and read news on the internet (89%) at
              least once a week, while only 45% read the written press at least
              once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ‚Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 – Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(13)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 25-39 prefer to read news on the internet (95%) and use
              online social networks (91%); 64% read the written{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ‚Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 – Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  press.<sup>(14)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 40-54 also prefer to read news on the internet (94%) and use
              online social networks (91%); written press, although the lowest
              in their preference, is still a popular mean{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ‚Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 – Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (80%).<sup>(15)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 55+ also have a high preference in watching television on a
              TV set (96%), while their second almost equal preference is the
              written press (93%); social networks appear to be the less popular
              mean{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ‚Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 – Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (55%).<sup>(16)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          As regards to the use of social media platforms in particular:
          <ul>
            <li>
              ages 15-24 mostly use Whatsapp (87%), Instagram (79%), followed by
              Youtube and Snapchat{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (76%)<sup>(17)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 also prefer Whatsapp (90%), followed by Facebook (80%)
              and Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (69%)<sup>(18)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 again prefer Whatsapp (84%), followed by Facebook (77%)
              and Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (65%)<sup>(19)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ present the least, yet relatively high use of social
              media, where Whatsapp is preferred by 69%, Facebook by 66% and
              Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (45%)<sup>(20)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>

        <li>
          <strong>As regards to accessing news</strong>, a 67% of the population
          shows an equal preference for TV and online news platforms, while
          Radio is preferred by 33% and social media platforms by 88%. Podcasts
          remain the least popular mean{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ‚Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              (4%).<sup>(21)</sup>
            </span>
          </CustomTooltip>{" "}
          In particular:
          <ul>
            <li>
              ages 15-24 prefer online news platforms (61%), social media
              platforms (51%) and the TV{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (48%)<sup>(22)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 again prefer online news platforms (81%), followed by
              the TV (48%) and social media platforms (43%) at a much lower
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  percentage<sup>(23)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also have a clear preference for online news platforms
              (73%), while some use the TV (63%) and lesser listen to the radio
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (35%)<sup>(24)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ have a clear preference for TV (86%), followed by a much
              lower percentage for the online news platforms (57%) and the
              written press{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (37%).<sup>(25)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to trust in news sources</strong>, interestingly
          the public TV and radio stations, as well as the written press
          (including online presence), seem to be the most trusted means across
          age{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ‚Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              groups.<sup>(26)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Public TV/Radio is trusted by 86% (highest) in ages 55+ and 49%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(27)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              The written press is trusted by 62% (highest) in ages 55+ and 50%
              (lowest) in ages
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ‚Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(28)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Influencers on social media, Youtube/video platforms and other
              online news platforms (podcasts, blogspots etc) seem to enjoy the
              least trust by all age groups, with their preferences ranging from
              1% in ages 55+ to 15% by ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(29)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              In terms of trust towards the Finnish media (“providing
              trustworthy information”) in particular, ages 55+ show the highest
              percentage of undisputable trust (37%), followed by ages 25-39
              (33%), ages 40-54 (30%) and last - yet close enough- by ages 15-24{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (29%).<sup>(30)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the acknowledgment of D&FN and their existence within
            the country
          </strong>
          , only 14% strongly consider it as an actual issue for Finland, 38%
          tends to believe it is an issue, while 44% in total either tend to, or
          totally disagree that this constitutes an{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              issue.<sup>(31)</sup>
            </span>
          </CustomTooltip>{" "}
          Furthermore, 58% of the population agree/or tend to agree that they
          often come across D&FN, while only 5% totally disagrees with that
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              statement.<sup>(32)</sup>
            </span>
          </CustomTooltip>{" "}
          Moreover, 77% feel or tend to feel confident in identifying D&FN,
          while 20% feel less or no confidence at all in this{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ‚Standard Eurobarometer STD96: Standard
                Eurobarometer 96 – Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              regard.<sup>(33)</sup>
            </span>
          </CustomTooltip>{" "}
          In terms of confidence,
          <ul>
            <li>
              Considering the respondents placement in the political spectrum,
              people placing themselves on the left (83%) feel most confident in
              this regard, followed by the people on the right (77%) and last
              the people in the centre{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ‚Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 – Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (72%).<sup>(34)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const SwedenProfile = () => {
  return (
    <div className="profile">
      <h3 id="sweden" className="section-title">
        Kingdom of Sweden:{" "}
        <CustomTooltip
          title={
            <div>
              Political system - Leaders: Sweden is a constitutional monarchy
              and King Carl XVI Gustaf is the head of state. The monarch has
              primarily ceremonial and representative duties. The Head of State
              is the foremost representative of the Kingdom and is kept informed
              on issues of national importance. In accordance with
              representative and parliamentary democracy, the parliament enacts
              the laws and makes the decisions, which the government and its
              agencies implement. Public-private partnership is at the core of
              "the Swedish model", which was developed by the Social Democrats,
              who have governed for most of the time since the 1920s. Ulf
              Kristersson is the prime minster since 2022 general election and
              heads a coalition government between the Moderate Party, Christian
              Democrats and Liberals, with external support from the far-right
              Sweden Democrats. Relative to its population size, Sweden has
              taken in far more migrants and refugees than any other EU country
              since the beginning of the refugee crisis in 2015. As reported,
              this has put pressure on public services, and has prompted a rise
              in support for the far-right Sweden Democrats party.
            </div>
          }
        >
          <span>
            Facts<sup>(1)</sup>
          </span>
        </CustomTooltip>
      </h3>
      <ul>
        <li>Capital: Stockholm</li>
        <li>Area: 450,295 sq km</li>
        <li>Population: 10.4 million</li>
        <li>
          Languages: Swedish, plus Sami, Finnish, Meankieli, Romani, Yiddish
        </li>
      </ul>

      <h4>Media & Media Literacy in Sweden</h4>
      <p>
        Sweden was the world’s first country to have adopted a press freedom law
        (1766) and it tends to hold media independence in great respect
        nevertheless, journalists have been targeted by threats, online hate
        campaigns and abusive{" "}
        <CustomTooltip
          title={
            <div>
              RSF - Reporters without borders, Sweden. Available at:
              <CountryLink link={"https://rsf.org/en/country/sweden"} />
            </div>
          }
        >
          <span>
            lawsuits.<sup>(2)</sup>
          </span>
        </CustomTooltip>{" "}
        There are currently five media companies that own a large majority of
        all newspapers in the country. In the audiovisual sector the market is
        dominated by four television and three radio groups (including publicly
        owned media). Swedes have traditionally been keen newspaper readers
        nonetheless the press is facing pressure from digital rivals. Regarding
        internet-digital use, on December 2021 there were 9.6 million internet
        users which comprise 96% of the{" "}
        <CustomTooltip
          title={
            <div>
              Internet World Stats - Usage and Population Statistics, European
              Union Internet Users, Population and Facebook Statistics.
              Available at:{" "}
              <CountryLink
                link={"https://www.internetworldstats.com/stats9.htm"}
              />
            </div>
          }
        >
          <span>
            population.<sup>(3)</sup>
          </span>
        </CustomTooltip>{" "}
        TV is the most popular medium, with radio and online media following
        closely. Sweden rates comparatively high for media freedom, specifically
        Sweden ranked fourth, after Denmark, in the 2023 RSF global media
        freedom{" "}
        <CustomTooltip
          title={
            <div>
              RSF - Reporters without borders, Press freedom index - 2023.
              Available at:{" "}
              <CountryLink link={"https://rsf.org/en/index?year=2023"} />
            </div>
          }
        >
          <span>
            index.<sup>(4)</sup>
          </span>
        </CustomTooltip>
      </p>

      <h4>Main findings Sweden</h4>
      <p>
        Sweden has been promoting media literacy and online safety through
        formal and informal learning. The government agency “the Swedish Media
        Council (Statens medieråd)” is gathering, interpreting and disseminating
        research on children's and young people's use of media. Through this
        channel, information and teaching materials are produced on Media and
        Information Literacy (MIL) for schools and libraries. The effort is part
        of the EU Commission's project Safer Internet, to promote a safer and
        better use of the internet and mobile technologies among children and
        young{" "}
        <CustomTooltip
          title={
            <div>
              European Commission – Youth Wiki, Sweden- Education and Training -
              Media literacy and safe use of new media. Last update: 28 November
              2023. Available at:
              <CountryLink
                link={
                  "https://nationalpolicies.eacea.ec.europa.eu/youthwiki/chapters/sweden/68-media-literacy-and-safe-use-of-new-media."
                }
              />
            </div>
          }
        >
          <span>
            people.<sup>(5)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        According to the European Media Literacy Index (MLI) Report for{" "}
        <CustomTooltip
          title={
            <div>
              Marin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute - Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf."
                }
              />
            </div>
          }
        >
          <span>
            2023<sup>(6)</sup>
          </span>
        </CustomTooltip>{" "}
        , Sweden is placed 5th out of 41 countries in total in the respective
        ranking, with a score of 71 points out of 100. Sweden is included in the
        1st cluster, “best-performing countries”, where Finland is first
        followed by Denmark, Norway, Estonia, Sweden and{" "}
        <CustomTooltip
          title={
            <div>
              Marin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute - Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf."
                }
              />
            </div>
          }
        >
          <span>
            Ireland.<sup>(7)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        From the data presented in the document for the Country Profiles some
        key takeaways are:
      </p>
      <p>
        <strong>In terms of daily media consumption</strong>, the majority read
        news on the internet (69%) online followed by social networks (61%),
        many watch television on a TV set (60%), followed by radio (50%) and
        those who read the written press (39%) and watch TV via internet (33%)
        and lastly podcasts{" "}
        <CustomTooltip
          title={
            <div>
              European Union (2022). ,Standard Eurobarometer STD96: Standard
              Eurobarometer 96 - Winter 2021-2022'. Available at:
              <CountryLink
                link={
                  "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                }
              />
            </div>
          }
        >
          <span>
            (15%).<sup>(8)</sup>
          </span>
        </CustomTooltip>
      </p>
      <ul>
        <li>
          Ages 15-24 more often use online social networks (98%) at least once a
          week and read news on the internet (88%) at least once a week, while
          almost half of that age group listen to the radio (49%) at least once
          a week followed closely by those who watch television on a TV set
          (48%) at least once a week and read the written press (48%) at least
          once a{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              week.<sup>(9)</sup>
            </span>
          </CustomTooltip>
        </li>
        <li>
          Ages 25-39 prefer to read news on the internet (91%) at least once a
          week and use social networks (90%) at least once a week, many listen
          to the radio (70%) at least once a week and watch television on a TV
          set (51%) at least once a week, while 43% read the written press at
          least once a{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              week.<sup>(10)</sup>
            </span>
          </CustomTooltip>
        </li>
        <li>
          Ages 40-54 present similarly share a high preference in reading news
          on the internet (95%) consumed at least once a week, and using social
          networks (84%) at least once a week, many listen to the radio (82%) at
          least once a week and watch television on a TV set (74%) at least once
          a week, use online networks (85%) at least once a week and read news
          on the internet (82%) at least once a week, while 58% read the written
          press at least once a{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              week.<sup>(11)</sup>
            </span>
          </CustomTooltip>
        </li>
        <li>
          Ages 55+ demonstrate a clear preference in watching television on a TV
          set (94%) consumed at least once a week, while their second preference
          is the radio (87%) consumed at least once a week; written press holds
          the highest percentage in this age group (86%) consumed at least once
          a week, followed by online news (79%) consumed at least once a week;
          use of social networks appears to be the less popular mean (59%)
          consumed at least once a{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              week.<sup>(12)</sup>
            </span>
          </CustomTooltip>
        </li>
        <li>
          As regards to the use of social media in particular:
          <ul>
            <li>
              ages 15-24 mostly use Snapchat (86%), Instagram (85%), followed by
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  Youtube (79%)<sup>(13)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 mostly use Facebook (84%) followed by Instagram (76%)
              and messenger{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (75%)<sup>(14)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also prefer Facebook (79%), followed by YouTube (58%),
              Instagram (58%) and Messenger{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (57%)<sup>(15)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ similarly prefer Facebook (74%), followed by Instagram
              (48%) and Messenger{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (43%)<sup>(16)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to accessing news</strong>, 65% of the population
          shows a clear preference for TV and online news platforms (60%), while
          Radio is preferred by 35% and social media and blogs by 25% and the
          written (printed) press by{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              23%.<sup>(17)</sup>
            </span>
          </CustomTooltip>{" "}
          In particular:
          <ul>
            <li>
              ages 15-24 prefer online news platforms (57%), social media
              platforms (47%) and TV{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (45%)<sup>(18)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 similarly prefer online news platforms (70%), followed
              by TV (47%) and then social media{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (34%)<sup>(19)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 have a preference for TV (69%) followed by online news
              platforms (64%) and the radio{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (39%)<sup>(20)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ similarly have a clear preference for TV (82%) followed
              by online news platforms (53%) and the radio{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (43%)<sup>(21)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to trust in news sources</strong>, interestingly
          the public TV and radio stations, as well as the written press, seem
          to be the most trusted means across age groups.
          <ul>
            <li>
              Public TV/Radio is trusted by 78% (highest) in ages 55+ and 54%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24;<sup>(22)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              the written press is trusted by 56% (highest) in 25-39 age group
              and 48% (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  40-54.<sup>(23)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Other online news platforms (12% at 40 -54 age group)
              Youtube/video platforms (16% at the 15-24 age group) and groups or
              friends I follow on social media or messaging platforms (10% by
              50+ age group) while interestingly 15% of 25-39 age group don’t
              know if they trust in news sources in{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  general<sup>(24)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the acknowledgment of D&FN and their existence within
            the country
          </strong>
          , 36% strongly consider it as an actual issue for Sweden, 40% tend to
          believe it is an issue, while 17% tend to disagree, 5% totally
          disagree that this constitutes an{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              issue.<sup>(25)</sup>
            </span>
          </CustomTooltip>{" "}
          Furthermore, 67% of Swedes agree/or tend to agree that they often come
          across D&FN, while 7% totally disagree with that{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              statement.<sup>(26)</sup>
            </span>
          </CustomTooltip>{" "}
          Moreover, 74% feel confident in identifying D&FN, while 25% feel less
          or no confidence at all in this{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022'. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              regard.<sup>(27)</sup>
            </span>
          </CustomTooltip>{" "}
          In terms of confidence,
          <ul>
            <li>
              Considering the respondents' level of education, people in the 20+
              age group are the most confident (78%), those who are still
              studying feel slightly less confident (69%) while ages 15-19 range
              from 58% to{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022'. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  63%.<sup>(28)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const FranceProfile = () => {
  return (
    <div className="profile">
      <h3 id="france" className="section-title">
        French Republic:{" "}
        <CustomTooltip
          title={
            <div>
              Political System and Leaders: France operates within a framework
              of a semi-presidential system, it is a republican State and a
              parliamentary democracy. The Parliament is bicameral and is made
              up of the National Assembly (Assemblée nationale) and the Senate
              (Sénat). Emmanuel Macron is the president of France since the May
              2017 presidential election. In 2018 President Macron's popularity
              witnessed a fall “as he tried to overhaul the economy, with major
              street protests in November 2018 over his attempt to wean the
              public off fossil fuels through price hikes”. President Macron has
              appointed Elisabeth Borne as prime minister in May 2022, she is
              France's second woman to hold the position of prime minister (the
              first was Edith Cresson in 1991-1992).
            </div>
          }
        >
          <span>
            Facts<sup>(1)</sup>
          </span>
        </CustomTooltip>
      </h3>
      <ul>
        <li>Capital: Paris</li>
        <li>Area: 643,801 sq km</li>
        <li>Population: 67.8 million</li>
        <li>Language: French</li>
      </ul>

      <h4>Media & Media Literacy in France</h4>
      <p>
        France has a free press and more than 100 daily newspapers, most of
        these are privately owned and are not linked to political parties. Even
        though France’s legal and regulatory framework is favourable to press
        freedom and editorial independence, it is reported that the mechanisms
        aimed at combating conflicts of interest in the media are insufficient.
        The independence of public broadcast media has been characterized as
        undermined due to the lack of long-term funding since the domestic TV
        licence fee’s elimination in 2022. While France has adopted some “new
        set of policing regulations that takes more account of journalists’
        rights during demonstrations, reporters continue to be subjected to
        police violence as well as physical attacks by{" "}
        <CustomTooltip
          title={
            <div>
              RSF - Reporters without borders, France. Available at:{" "}
              <CountryLink link={"https://rsf.org/en/country/france"} />
            </div>
          }
        >
          <span>
            demonstrators”.<sup>(2)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        The media landscape of France offers a wide range of choices in all
        categories at both national and local level, France is a force in
        international broadcasting. The daily Ouest-France is the best-selling
        newspaper and the public television channels and radio stations of
        France are Télévisions and Radio France. Television is France's most
        popular medium. Public Radio France serves a domestic audience and
        French overseas territories and command large{" "}
        <CustomTooltip
          title={
            <div>
              BBC News, France media guide, June 19, 2023. Available at:
              <CountryLink
                link={"https://www.bbc.com/news/world-europe-17299010"}
              />
            </div>
          }
        >
          <span>
            audiences<sup>(3)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        France rates comparatively low for media freedom, specifically France
        ranked 24th in the 2023 Reporters Without Borders (RSF) global media
        freedom{" "}
        <CustomTooltip
          title={
            <div>
              RSF - Reporters without borders, Press freedom index - 2023.
              Available at: <CountryLink link={"https://rsf.org/en/index"} />
            </div>
          }
        >
          <span>
            index.<sup>(4)</sup>
          </span>
        </CustomTooltip>{" "}
        Regarding internet-digital use, on December 2021 there were
        approximately 60 million internet users which comprise 92% of the
        <CustomTooltip
          title={
            <div>
              Internet World Stats – Usage and Population Statistics, European
              Union Internet Users, Population and Facebook Statistics.
              Available at:{" "}
              <CountryLink
                link={"https://www.internetworldstats.com/stats9.htm"}
              />
            </div>
          }
        >
          <span>
            population.<sup>(5)</sup>
          </span>
        </CustomTooltip>
      </p>

      <h4>Main findings France</h4>
      <p>
        Media literacy education in France is perceived as vital in preserving
        democracy, promoting critical thinking and as such, it begins at a young
        age. At a national level, CLEMI (Le centre pour l’éducation aux médias
        et à l’information) is responsible for Media and Information Literacy
        (MIL) in the French education system, it reports to the Ministry of
        Education, Youth and Sport (Ministère de L’Éducation, de la Jeunesse et
        des Sports). The approach used is based on skills rather than on tools
        with the aim of providing children with critical thinking; being able to
        question and verify the information they are exposed to. Apart from the
        national curriculum and official media policies there are several media
        literacy initiatives from various stakeholders taking place in{" "}
        <CustomTooltip
          title={
            <div>
              6. EDMO, France – Media Literacy country profile-. Available at:
              <CountryLink
                link={"https://edmo.eu/country-profile/france/"}
              />{" "}
              <br /> 7. European Commission – Youth Wiki, France- Education and
              Training - Media literacy and safe use of new media. Last update:
              28 November 2023. Available at:{" "}
              <CountryLink
                link={
                  "https://national-policies.eacea.ec.europa.eu/youthwiki/chapters/france/68-media-literacy-and-safe-use-of-new-media"
                }
              />
            </div>
          }
        >
          <span>
            France.<sup>(6, 7)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        Internet user skills in France are slightly above the EU average,
        according to the 2022 European Commission DESI index.{" "}
      </p>
      <p>
        The Reuters Institute Digital News Report 2022 found low levels of trust
        in news compared to other countries, finding which agrees with the
        evidence presented in this analysis. Furthermore, what was specified was
        that trust was particularly low for online news and news on social
        media. What EDMO reports is that according to the 2021 Media Pluralism
        Monitor, this lack of trust is related to a rise in conspiracy theories.
        The Media Pluralism Monitor reports (2021 and 2022), as cited by EDMO,
        concluded that media literacy programs in France are strong however
        these are unequally implemented across the{" "}
        <CustomTooltip
          title={
            <div>
              EDMO, France – Media Literacy country profile-. Available at
              <CountryLink link={"https://edmo.eu/country-profile/france/"} />
            </div>
          }
        >
          <span>
            country.<sup>(8)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        According to the European Media Literacy Index (MLI) Report for{" "}
        <CustomTooltip
          title={
            <div>
              Мarin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute – Sofia.
              Available at:
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf"
                }
              />
            </div>
          }
        >
          <span>
            2023<sup>(9)</sup>
          </span>
        </CustomTooltip>{" "}
        France is placed 17th out of 41 countries in total in the respective
        ranking, with a score of 57 points out of 100. France is included in the
        2nd cluster consisting in the “well-performing countries”, while Belgium
        (10th) and Germany (11th) are also included in this cluster.
      </p>

      <p>
        From the data presented in the document for the Country Profiles some
        key takeaways are:
      </p>
      <ul>
        <li>
          <strong>In terms of daily media consumption</strong>, TV remains
          predominant (73%), and is followed by radio (48%); next to follow are
          the use of social networks (39%) and the online news (35%). A rather
          surprising element is that 40% of the population has no access to
          social{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              networks.<sup>(10)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Ages 15-24 have a clear preference for online social networks
              (92%) to consume at least once a week, as well as for reading news
              on the internet (86%), while only 27% read the written press also
              at least once a week.
            </li>
            <li>
              Ages 25-39 have an equal preference for TV and the use of online
              social networks (79%) to consume at least once a week, while the
              news on the internet are almost equally popular (78%) to consume
              at least once a week; written press is again last in their
              preference (38%) to consume at least once a week.{" "}
            </li>
            <li>
              Ages 40-54 also prefer the TV (87%) to consume at least once a
              week and the radio (75%) to consume at least once a week; written
              press again comes last (39%) to be consumed at least once a week.
            </li>
            <li>
              Ages 55+ also have a high preference in watching television on a
              TV set (94%) to consume at least once a week, while their second
              preference is the radio (68%) to consume at least once a week;
              social networks appear to be the less popular means (32%) to be
              consumed at least once a week.{" "}
            </li>
          </ul>
        </li>
        <li>
          As regards to the <strong>use of social media platforms</strong> in
          particular:{" "}
          <ul>
            <li>
              ages 15-24 mostly use Instagram (74%), Youtube (73%), followed by
              Facebook{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (46%)<sup>(11)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 prefer Facebook (73%), followed by Youtube (59%) and
              Instagram{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (52%)<sup>(12)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 again prefer Facebook (69%), followed by Youtube (47%)
              and Whatsapp{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (43%)<sup>(13)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ present the least, yet relatively high use of social
              media, where Facebook is preferred by 57%, Whatsapp by 43% and
              Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (30%)<sup>(14)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to accessing news</strong>, 78% of the population
          shows a clear preference for TV, while Radio comes second (45%);
          online news platforms are third (29%) and Podcasts are last{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              (4%).<sup>(15)</sup>
            </span>
          </CustomTooltip>{" "}
          In particular:
          <ul>
            <li>
              ages 15-24 prefer the TV (67%), and their second equal preferences
              are Youtube/video platforms and social media platforms{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (37%)<sup>(16)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 again prefer the TV (70%), followed by the radio (42%)
              and online news platforms (31%) at a much lower{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  percentage<sup>(17)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also have a clear preference for the TV (80%), while
              some use the radio (52%) and lesser use the online news platforms
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (29%)<sup>(18)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ have again a clear preference for TV (86%), followed by a
              much lower percentage for the radio (48%) and the written press
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (30%).<sup>(19)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to trust in news sources</strong>, interestingly
          the public TV and radio stations, as well as the written press
          (including online presence), seem to be the most trusted means across
          age{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              groups.<sup>(20)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Public TV/Radio is trusted by 56% (highest) in ages 55+ and 39%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(21)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              the written press is trusted by 49% (highest) in ages 55+ and 36%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(22)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Influencers on social media, Youtube/video platforms,
              people-groups/friends followed on social media, and other online
              news platforms (podcasts, blogspots etc) seem to enjoy the least
              trust by all age groups, with their preferences ranging from 1% in
              ages 55+ to 21% by ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(23)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the acknowledgment of D&FN and their existence within
            the country
          </strong>
          , 37% strongly consider it as an actual issue for France, 48% tend to
          believe it is an issue, while only 10% in total either tend to, or
          totally disagree that this constitutes an{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              issue.<sup>(24)</sup>
            </span>
          </CustomTooltip>{" "}
          Furthermore, 85% of the population agree/or tend to agree that they
          often come across D&FN, while only 3% totally disagrees with that
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              statement.<sup>(25)</sup>
            </span>
          </CustomTooltip>{" "}
          Moreover, 61% feel or tend to feel confident in identifying D&FN,
          while 33% feel less or no confidence at all in this{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              regard.<sup>(26)</sup>
            </span>
          </CustomTooltip>{" "}
          In terms of confidence,
          <ul>
            <li>
              Considering the respondents' age group, ages 15-24 feel more
              confident in their capacity to identify D&FN (67%), followed by
              ages 25-39 (66%) and ages 40-54 (62%); ages 55+ are the least
              confident in this regard{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (55%).<sup>(27)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ○ Considering the respondents' level of education, people that are
              still studying (67%) and people 20+ (66%) feel almost equally
              confident, while people 16-19 (56%) and 15- (53%) appear to be
              less{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  confident.<sup>(28)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const BelgiumProfile = () => {
  return (
    <div className="profile">
      <h3 id="belgium" className="section-title">
        Kingdom of Belgium:{" "}
        <CustomTooltip title="Political System – Leaders: Belgium, located in North-western Europe, is a federal constitutional monarchy with a parliamentary system; King Philippe is the head of state, and Alexander De Croo the Prime Minister, a liberal Flemish politician who leads a seven-party coalition of liberals, socialists, greens and Christian democrats.">
          <span>
            Facts<sup>(1)</sup>
          </span>
        </CustomTooltip>
      </h3>
      <ul>
        <li>Capital: Brussels</li>
        <li>Area: 30,528 sq km</li>
        <li>Population: 11.5 million</li>
        <li>Languages: Dutch, French, German</li>
      </ul>

      <h4>Media & Media Literacy in Belgium</h4>
      <p>
        Belgium’s complex institutional organization lies on both regional and
        linguistic grounds, with three regions, i.e. the Flemish (Flanders),
        Walloon (Wallonia) and Brussels (capital region) and three official
        languages, i.e. Dutch, French and German; the Dutch-speaking Flemish
        community comprises about almost 60% of the population, the
        French-speaking community comprises about 40% and German-speaking
        community just under 1% of the population (in Ostbelgien). The
        respective language lines also affect the educational and cultural
        policies, as the latter are decided at a community level. This
        particularity directly relates to the current division of the media
        markets into separate segments and inevitably increases cross-border
        influence from strong neighbouring language media (i.e. France and the
        Netherlands). Similarly, media literacy policies are also segmented, as
        they tend to address either one community or the other; each Community
        has its own (audiovisual) media law and a separate media regulator with
        sometimes varying tasks and{" "}
        <CustomTooltip
          title={
            <div>
              {" "}
              EUMEPLAT project (GA 101004488), “D1.2: Patterns in media
              consumption: regional models”, October 2021,
              <CountryLink
                link={
                  "https://www.eumeplat.eu/wp-content/uploads/2021/12/D1.2_Patterns-in-media-consumption_regional-models.pdf"
                }
              />
            </div>
          }
        >
          <span>
            competences.<sup>(2)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        The country’s distinctiveness regarding its territorial and linguistic
        borders and their effects on the media landscape has also shaped the
        flow of disinformation. In particular, the issue of migration has been
        instrumentalized, especially by right-wing political parties; by using
        particular events (e.g. the terrorist attacks claimed by ISIS in 2015,
        the Syrian migration wave etc), the latter exacerbated the given facts
        in their media campaigns to reinforce their xenophobic narratives.
        Similarly, political parties instrumentalized the given prejudice
        between Dutch-speaking and French-speaking communities (also triggered
        by the rise of independence movements in Flanders), by spreading
        disinformation messages aggravating identity divisions and overall
        tensions. Furthermore, the COVID-19 pandemic revealed a growing presence
        of online communities opposing mandatory vaccination, whose online
        disinformation campaigns (mainly via social media platforms) were
        followed by violent demonstrations in Brussels against the health pass
        and restrictions. These communities’ narratives also included messages
        relevant to technology scepticism, e.g. the allegedly damaging effects
        of 5G on{" "}
        <CustomTooltip
          title={
            <div>
              EU DisinfoLab / EDMO BELUX, Disinformation landscape in Belgium,
              May 2023,{" "}
              <CountryLink
                link={
                  "https://edmo.eu/wp-content/uploads/2023/05/20230509_BE_DisinfoFS.pdf"
                }
              />
            </div>
          }
        >
          <span>
            health.<sup>(3)</sup>
          </span>
        </CustomTooltip>
      </p>

      <h4>Main findings Belgium: </h4>
      <p>
        According to the European Media Literacy Index (MLI) Report for 2023,
        Belgium is placed 10th out of 41 countries in total in the respective
        ranking, with a score of 61 points out of 100. Belgium is included in
        the 2nd cluster consisting in the “well-performing” countries. Comparing
        the Index 2023 results and the Index 2022 results, Belgium demonstrated
        one of the biggest improvements as it managed to move 3 positions up the
        ranking; it should be noted though that this was not due to the
        improvement of the country’s actual score, as that remained the same,
        but rather to deterioration of the other countries’ respective
        <CustomTooltip
          title={
            <div>
              The Media Literacy Index 2023 – Measuring Vulnerability of
              Societies to Disinformation, June 2023,{" "}
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf"
                }
              />
            </div>
          }
        >
          <span>
            performances.<sup>(4)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        According to the 2023 Media Pluralism Monitor{" "}
        <CustomTooltip
          title={
            <div>
              Centre for Media Pluralism and Media Freedom, Monitoring Media
              Pluralism in the Digital Era: Belgium Country Report, June 2023,
              Available at:{" "}
              <CountryLink
                link={
                  "https://cadmus.eui.eu/bitstream/handle/1814/75715/Belgium_results_mpm_2023_cmpf.pdf?sequence=1&isAllowed=y"
                }
              />
            </div>
          }
        >
          <span>
            (MPM)<sup>(5)</sup>
          </span>
        </CustomTooltip>
        , the country’s overall scores are considered to be positive, as the
        efforts in the field of media literacy are continuous. However, the main
        two language communities demonstrate a notable difference; while the
        Flemish Community has had media literacy included in formal and
        non-formal educational settings for some time, this process is still
        under development in the French Community. Moreover, concerns arise
        regarding the market plurality, also related to the different language
        communities, due to the high concentration level of the media actors and
        the increasing market consolidation. In this regard, available
        information on the market share and/or ownership of digital native news
        media is particularly limited, as the latter are not (yet) obliged to
        follow the same media transparency rules imposed by their community
        regulators. The limited scope of action for each legislator – based on
        the country’s institutional structure - leads to the lack of cross-media
        ownership restrictions, as well as lack of thresholds in media
        legislation.{" "}
      </p>
      <p>
        The COVID-19 pandemic had its inevitable impact on the Belgian media
        landscape as well, where freelance journalists and smaller media outlets
        experienced the greatest losses, despite the State and Community
        government additional support measures. Also related to the role of the
        media throughout the pandemic, the report highlights an ongoing
        “worrisome general atmosphere of distrust and even hostility towards
        country's journalists in general, regardless of ideology or{" "}
        <CustomTooltip
          title={
            <div>
              Centre for Media Pluralism and Media Freedom, Monitoring Media
              Pluralism in the Digital Era: Belgium Country Report, June 2023,
              Available at:{" "}
              <CountryLink
                link={
                  "https://cadmus.eui.eu/bitstream/handle/1814/75715/Belgium_results_mpm_2023_cmpf.pdf?sequence=1&isAllowed=y"
                }
              />{" "}
              p.7
            </div>
          }
        >
          <span>
            affiliation”<sup>(6)</sup>
          </span>
        </CustomTooltip>
        , while particular attention is drawn to cases of online aggression
        against female journalists and “more notably those of ethnic background
        of a minority{" "}
        <CustomTooltip
          title={
            <div>
              Centre for Media Pluralism and Media Freedom, Monitoring Media
              Pluralism in the Digital Era: Belgium Country Report, June 2023,
              Available at:{" "}
              <CountryLink
                link={
                  "https://cadmus.eui.eu/bitstream/handle/1814/75715/Belgium_results_mpm_2023_cmpf.pdf?sequence=1&isAllowed=y"
                }
              />{" "}
              p.9
            </div>
          }
        >
          <span>
            group”<sup>(7)</sup>
          </span>
        </CustomTooltip>
        . It further clarifies that this trend has not yet reached levels of
        violence, yet it points out the necessity of preventing further
        escalation.
      </p>
      <p>
        The fragmented media landscape similarly affects social inclusiveness,
        as the efforts to guarantee inclusion of minorities and marginalised
        groups has proven particularly challenging. In this regard, the existing
        legal framework against hate speech appears to be problematic in terms
        of enforcement, as additional amendments to the Constitution are deemed
        necessary. Currently{" "}
        <CustomTooltip
          title={
            <div>
              UNIA, For equality, against discrimination. Available at:
              <CountryLink link={"https://www.unia.be/en"} />
            </div>
          }
        >
          <span>
            UNIA,<sup>(8)</sup>
          </span>
        </CustomTooltip>{" "}
        the country’s competent institute for monitoring and acting against
        discrimination, has been particularly active the past few years in
        prosecuting several hate speech cases in the online environment. In
        terms of gender equality, it should be noted that females appear to be
        underrepresented in management, board or CEO positions in the media
        industry, and similarly underrepresented in the news media, both as
        ‘news subjects’ and as ‘reporters/presenters’.
      </p>
      <p>
        Political independence of the available traditional media seems to be at
        a high level. Although the legal safeguards on political control and
        influence only apply to the broadcasting sector (radio and television),
        in contrast to newspapers and media distribution, the different media
        practitioners seem to adhere to the informal mechanisms in place
        maintaining their political independence, including the general
        constitutional protection of freedom of expression and press freedoms,
        and the effective self-regulatory codes of ethics for journalists. This,
        however, does not seem to be the case for the digital native media;
        considering the lack of transparency regarding ownership and/or control,
        affiliations to political groups cannot be confirmed, nor denied.
      </p>
      <p>
        According to the RSF's World Press Freedom 2023 Index, Belgium is placed
        31st out of 180 countries in total, with a score of 76.47 points,
        demonstrating an 8-positions drop in comparison to the 2022 Index (23rd
        in 2022, with a score of 78.86 points). The report re-affirms that
        public broadcasting is, in principle, protected against political
        pressure and the media in general enjoy solid legal and constitutional
        guarantees. The level of public trust towards the Belgian press appears
        to be high, especially in Flanders. Nevertheless, the COVID-19 pandemic
        brought to light several cases of disinformation, as well as intense
        criticism towards the Flemish media, as the latter were accused of
        covering the health emergency from a pro-government perspective.
        Moreover, journalists covering demonstrations against COVID-19-related
        measures were subjected to intimidation and threats by protesters; this,
        along with cases of police violence, as well online threats on racist
        and/or sexist grounds, led to an increased sense of lack of safety among
        journalists. Following these events, and in an effort to address the
        growing public distrust, Belgium officials announced the establishment
        of an experts’ group to investigate the massive dissemination of fake
        news by the anti-vaccine movement; in addition, the Wallonia-Brussels
        Federation adopted, in early 2022, a media education curriculum to
        develop students’ capabilities in critical{" "}
        <CustomTooltip
          title={
            <div>
              Reporters Without Borders, Word Press Freedom 2023 Index: Belgium
              Country Profile,{" "}
              <CountryLink link={"https://rsf.org/en/country/belgium"} />
            </div>
          }
        >
          <span>
            analysis<sup>(9)</sup>
          </span>
        </CustomTooltip>
        .
      </p>
      <p>
        Although Internet user skills in Belgium are very close to the EU
        average, there seem to be concerns over the high proportion of people
        lacking essential digital skills. In 2021, 46% of 16–74-year-olds in
        Belgium were classified as “digitally vulnerable”, with the largest
        share found in Wallonia (49%), while in Flanders the share equals to
        46%. Brussels residents appear to be slightly less vulnerable, while 39%
        are considered to be “at risk”. A noteworthy remark is that the most
        “digitally vulnerable” people tend to be Belgians from low-income groups
        and those with low levels of education. This group also has the least
        access to so-called “essential” digital services, such as banking,
        health portals, government sites, or e-commerce. Furthermore, although
        younger people are considered to be “digitally vulnerable”, they are
        also dealing with issues related to digital access and skills; out of
        the 90% of Belgians who own a phone, one in five internet users only
        owns a smartphone to go{" "}
        <CustomTooltip
          title={
            <div>
              The Brussels Times, Digital divide: Half of Belgians lack
              essential online skills, 2/9/2022,{" "}
              <CountryLink
                link={
                  "https://www.brusselstimes.com/282545/half-of-belgians-lack-essential-digital-skills"
                }
              />
            </div>
          }
        >
          <span>
            online.<sup>(10)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        From the data presented in the document for the Country Profiles some
        key takeaways are:
      </p>
      <ul>
        <li>
          <strong>In terms of daily media consumption</strong>, TV remains
          predominant (75%), and is followed by radio (52%), social networks
          (47%), online media (44%), written press (28%) and podcasts (6%).
          Interestingly, 20% of the population has no access to social{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              networks.<sup>(11)</sup>
            </span>
          </CustomTooltip>{" "}
          in particular:
          <ul>
            <li>
              Ages 15-24 more often use online social networks (91%) at least
              once a week and read news on the internet (87%) at least once a
              week, while only 36% read the written press at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(12)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 25-39 almost equally watch television on a TV set (83%) at
              least once a week, use online networks (85%) at least once a week
              and read news on the internet (82%), while 46% read the written
              press at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(13)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 40-54 present similarly high preferences in watching
              television on a TV set (89%) consumed at least once a week,
              listening to the radio (86%), reading news online (83%) at least
              once a week and using social networks (87%) at least once a week;
              written press is the lowest in their preference (57%) consumed at
              least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(14)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 55+ demonstrate a clear preference in watching television on
              a TV set (97%) at least once a week, while their second preference
              is the radio (86%) consumed at least once a week; written press
              holds the highest percentage in this age group (63%) consumed at
              least once a week, followed by online news (61%) consumed at least
              once a week; use of social networks appears to be the less popular
              mean (47%) consumed at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(15)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to the use of social media in particular: </strong>
          <ul>
            <li>
              ages 15-24 mostly use Instagram (78%), followed by Facebook (76%)
              and Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (75%)<sup>(16)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 mostly use Facebook (76%) followed by Whatsapp{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (65%)<sup>(17)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also prefer Facebook (77%), followed by a lesser use of
              Whatsapp{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (53%)<sup>(18)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ present the least use of social media, where Facebook is
              preferred by 68% and Whatsapp by{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  43%.<sup>(19)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to accessing news</strong>, 70% of the population
          shows a clear preference for TV, while Radio is preferred by 48% and
          online news platforms by 37%. Podcasts remain the least popular mean{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              (3%).<sup>(20)</sup>
            </span>
          </CustomTooltip>{" "}
          In particular:
          <ul>
            <li>
              ages 15-24 prefer social media platforms (49%) and TV{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (46%)<sup>(21)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 prefer the TV (58%), while the radio (42%) and online
              news platforms are next{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (40%)<sup>(22)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also have a clear preference for TV (75%), while some
              use the radio{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (58%)<sup>(23)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ similarly have a clear preference for TV (83%) and the
              radio{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (54%).<sup>(24)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to trust in news sources</strong>, interestingly
          the public TV and radio stations, as well as the written press, seem
          to be the most trusted means across age{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              groups.<sup>(25)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Public TV/Radio is trusted by 64% (highest) in ages 55+ and 40%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  15-24.<sup>(26)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              the written press is trusted by 55% (highest) in ages 55+ and 47%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  25-39.<sup>(27)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Other online news platforms (podcasts etc), Youtube/video
              platforms and influencers seem to enjoy the least trust by all age
              groups, ranging from 1% to{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  12%.<sup>(28)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              only within ages 15-24 the trust percentage is slightly higher,
              ranging from 14% to{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  18%.<sup>(29)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the acknowledgment of D&FN and their existence within
            the country
          </strong>
          , only 19% strongly consider it as an actual issue for Belgium, 49%
          tend to believe it is an issue, while 30% in total either tend to, or
          totally disagree that this constitutes an{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              issue.<sup>(30)</sup>
            </span>
          </CustomTooltip>{" "}
          Furthermore, 77% of Belgians agree/or tend to agree that they often
          come across D&FN, while 22% totally disagree with that{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              statement.<sup>(31)</sup>
            </span>
          </CustomTooltip>{" "}
          Moreover, 64% feel confident in identifying D&FN, while 34% feel less
          or no confidence at all in this{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              regard.<sup>(32)</sup>
            </span>
          </CustomTooltip>{" "}
          In terms of confidence,
          <ul>
            <li>
              Considering the respondents' age groups, ages 15-24 feel confident
              at 69% (highest) and ages 55+ at 61% (lowest), while the other age
              groups are located in{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  between.<sup>(33)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Considering the respondents' level of education, people that are
              still studying are the most confident (70%), ages 20+ feel
              slightly less confident (67%), while ages 15-19 range from 55% to
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  60%.<sup>(34)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Considering the respondents placement in the political spectrum,
              interestingly 65%-68% from all positions (left, centre, right)
              feel equally confident in this{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:{" "}
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  regard.<sup>(35)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const GermanyProfile = () => {
  return (
    <div className="profile">
      <h3 id="germany" className="section-title">
        Federal Republic of Germany:{" "}
        <CustomTooltip title="Political System – Leaders: Germany, Europe's largest economy and the most populous country in the EU, is located in the western region of central Europe and is a federal, parliamentary, representative democratic republic. The president Frank-Walter Steinmeier is the head of state, and the chancellor Olaf Scholz is the head of government since December 2021; the latter formed a coalition with the Greens and business-friendly Free Democrats, becoming the first Social Democrat chancellor since 2005, while taking over from the Christian Democrat Angela Merkel, Germany's first female chancellor, who governed for 16 years (in coalition with either the Free Democrats or the Social Democrats). The main characteristic of Germany’s federal state is that responsibilities for certain subject areas are divided between the federal government and the individual states .">
          <span>
            Facts<sup>(1)</sup>
          </span>
        </CustomTooltip>
      </h3>
      <ul>
        <li>Capital: Berlin</li>
        <li>Area: 357,022 sq km</li>
        <li>Population: 83.6 million</li>
        <li>Language: German</li>
      </ul>

      <h4>Media & Media Literacy in Germany</h4>
      <p>
        Media regulation is under the states, while each of the latter has its
        own state media law and press law. Among the few exceptions of federal
        regulations are the State Media Treaty (where the federal states
        concluded on a state treaty) and the Network Enforcement Act (federal
        law); these two examples display a trend in the current regulatory
        frameworks to increasingly take digital platforms into account. The
        German federal government faced challenges in the area of media and
        digital policy, due to disagreements within the coalition, also related
        to the European Commission's draft AI Regulation and the discussion on
        chat control. Furthermore, the Russian war of aggression in Ukraine, its
        social consequences and the energy crisis also affected the press
        sector, particularly due to the increased costs of paper and{" "}
        <CustomTooltip
          title={
            <div>
              Centre for Media Pluralism and Media Freedom, Monitoring Media
              Pluralism in the Digital Era: Germany Country Report, June 2023,{" "}
              <CountryLink
                link={
                  "https://cadmus.eui.eu/bitstream/handle/1814/75723/Germany_results_mpm_2023_cmpf.pdf?sequence=1&isAllowed=y"
                }
              />
            </div>
          }
        >
          <span>
            energy.<sup>(2)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        Germany's competitive television market is the largest in Europe (more
        than 38 million TV households), while the country is home to some of the
        world's largest media clusters. The numerous regional and national
        public broadcasters - organised in line with the federal political
        structure - compete for audiences with powerful commercial operators,
        while each of Germany's 16 regions regulates its own private and public
        broadcasting. TV networks Das Erste and ZDF, and Deutschlandradio are
        the national public broadcasters, while each household pays a
        "broadcasting contribution". Deutsche Welle (DW) is the country's
        international broadcaster. Newspapers and the non-tabloid press are
        particularly popular and, despite the many national newspapers, the
        press is strongest at the regional and local level. The German
        constitution enshrines media freedom and the press is considered to be
        independent; the display of swastikas and statements endorsing Nazism
        are{" "}
        <CustomTooltip
          title={
            <div>
              BBC, Germany Media Guide, updated 28/08/2023,{" "}
              <CountryLink
                link={"https://www.bbc.com/news/world-europe-17301193"}
              />
            </div>
          }
        >
          <span>
            illegal.<sup>(3)</sup>
          </span>
        </CustomTooltip>
      </p>

      <h4>Main findings Germany</h4>
      <p>
        According to the European Media Literacy Index (MLI) Report for 2023,
        Germany is placed 11th out of 41 countries in total in the respective
        ranking, with a score of 61 points out of 100. Germany is included in
        the 2nd cluster consisting in the “well-performing” countries. Comparing
        the Index 2023 results and the Index 2022 results, Germany experienced a
        drop by 2 positions in the ranking (9th in 2022), accompanied by the
        loss of one point in the respective score{" "}
        <CustomTooltip
          title={
            <div>
              Marin Lessenski, Statistical processing: Petia Brainova, Dragomira
              Belcheva, “Bye, bye, birdie”: Meeting the Challenges of
              Disinformation. The Media Literacy Index 2023. Measuring
              Vulnerability of Societies to Disinformation. Policy brief. Media
              Literacy Index 2023 Report. Open Society Institute - Sofia.
              Available at:{" "}
              <CountryLink
                link={
                  "https://osis.bg/wp-content/uploads/2023/06/MLI-report-in-English-22.06.pdf"
                }
              />
            </div>
          }
        >
          <span>
            (62/100 in 2022)<sup>(4)</sup>
          </span>
        </CustomTooltip>
        .
      </p>
      <p>
        According to the 2023 Media Pluralism Monitor{" "}
        <CustomTooltip
          title={
            <div>
              Centre for Media Pluralism and Media Freedom, Monitoring Media
              Pluralism in the Digital Era: Germany Country Report, June 2023,{" "}
              <CountryLink
                link={
                  "https://cadmus.eui.eu/bitstream/handle/1814/75723/Germany_results_mpm_2023_cmpf.pdf?sequence=1&isAllowed=y"
                }
              />
            </div>
          }
        >
          <span>
            (MPM)<sup>(5)</sup>
          </span>
        </CustomTooltip>{" "}
        the country’s overall scores are considered to be satisfactory, as media
        diversity benefits from strong public broadcasting. However, major
        digital communication platforms are seen as a threat to media diversity,
        due to their large share of advertising budgets and their increasing
        role as gatekeepers for news and channels for disinformation. German
        lawmakers began to introduce legal regulations to counter these risks,
        prescribing transparency and non-discrimination for media intermediaries
        (e.g., social networks, search engines) and extending journalistic due
        diligence to all distribution channels of information services
        (Instagram, YouTube, etc.). However, some of these risks are still
        considered to be high in the digital environment, particularly in the
        areas of Fundamental Protection, Political Independence and Social
        Inclusiveness; although recent media law legislation has been focused on
        the digital sphere, concerns have risen that some critical issues have
        not been addressed, such as equal opportunities in online election
        campaigns and insufficient media concentration law.{" "}
      </p>
      <p>
        Regarding the protection of Freedom of Expression, this is protected by
        law and the German state does not apply arbitrary censorship on the
        Internet. In 2022, the "Act to Combat Right-Wing Extremism and Hate
        Crime" came into force, while the “Network Enforcement Act/NetzDG”
        (effective since 2018) obliges social networks to report certain content
        to the Federal Criminal Police Office if there are concrete indications
        relevant to criminal law that pose a threat to the democratic
        constitutional state, a violation of public order, child pornography,
        threats against life, sexual self-determination, physical integrity or
        personal freedom and are not justified. However, in 2022 the Cologne
        Administrative Court ruled that some provision of the NetzDG violate the
        country-of-origin principle of the E-Commerce Directive and that the
        Federal Office of Justice is not independent enough to monitor
        compliance with the obligations under the NetzDG as the competent
        authority. Likewise, in 2023 the OVG Münster also exempted Meta from the
        application of the new cross-appeal procedure on similar grounds.{" "}
      </p>
      <p>
        In terms of Social Inclusiveness, public broadcasters in Germany
        currently have a clear mandate to promote integration and social
        cohesion. Although there are no fixed public broadcasting times for
        certain groups, plurality is guaranteed by the internal bodies of the
        broadcasters; in addition, although minorities not recognized by law do
        not have systematic access to public airtime, they can do so through
        other programs and collaborations. As regards to Gender Equality, in
        recent years more and more females have been appointed to management
        positions, while Deutsche Welle for example achieved a weighted female
        representation of 50%. A noteworthy remark in terms of inclusiveness is
        that, as the reform of public broadcasting relies on digital
        transformation and the conversion of certain programs to online
        offerings, concerns arise on whether this reform will be at the expense
        of the older generation, since the latter continues to watch mainly
        classic TV.
      </p>
      <p>
        Media Literacy on the other hand appears to be an increasing concern in
        the country, although it is currently considered to be a “medium” risk.
        The state media authorities are responsible for promoting media literacy
        (which is financed by the broadcasting contribution paid by households),
        projects aimed at improving media literacy are being funded, while
        combating hate speech and disinformation are highly prioritized.
        However, the ground for current critique is that media competence has
        traditionally been assigned to the individual state media authorities as
        a "state matter"; nationwide issues, such as disinformation, are
        therefore addressed in a federal "patchwork quilt" with varying degrees
        of intensity. Furthermore, promoting media competence in schools can
        also be assigned to the area of voluntary curricula; federal differences
        can be found here as well, as this remains a critical issue throughout
        Germany. Regarding the digital environment in particular, the two
        digital laws, the Network Enforcement Act and the Interstate Media
        Treaty, address the main issues of disinformation and hate speech
        (including deletion obligations, journalistic due diligence obligations,
        and transparency and non-discrimination), yet the extent to which the
        laws can provide a remedy has not yet been proven.{" "}
      </p>
      <p>
        According to the RSF's World Press Freedom 2023{" "}
        <CustomTooltip
          title={
            <div>
              Reporters Without Borders, Word Press Freedom 2023 Index: Germany
              Country Profile,{" "}
              <CountryLink link={"https://rsf.org/en/country/germany"} />
            </div>
          }
        >
          <span>
            Index<sup>(6)</sup>
          </span>
        </CustomTooltip>{" "}
        Germany is placed 21st out of 180 countries in total, with a score of
        81.91 points, demonstrating a 5-positions drop in comparison to the 2022
        Index (16th in 2022, with a score of 82.04 points). Although Germans in
        general are favourable towards journalism, violence and verbal attacks
        seem to be on the rise. Economic reasons have led to the erosion of
        media pluralism since the 1990s, where local newspapers were most
        affected. Previously large selling tabloids and quality papers lost a
        great share of their readers as the online versions became increasingly
        popular. Although the internet and social networks created new
        possibilities for independent reporting, big companies sometimes use
        Strategic Lawsuits Against Public Participation (SLAPPs) to intimidate
        the press. Moreover, female journalists and people of colour covering
        gender issues and racism deal with increasing hostility on social
        networks. Similarly, journalists covering the COVID-19-related measures
        were also increasingly accused of being pro-government, while some were
        also subjected to attacks during demonstrations. Most attacks against
        journalists in general are attributable to right-wing or far-right
        actors, while some are perpetrated by individuals close to the radical
        left, as well as the police.
      </p>
      <p>
        Moreover, although the level of basic digital skills and basic digital
        content creation skills is lower than the EU average, the proportion of
        specialists in information and communications technology (ICT) exceeds
        the EU average; 49% of individuals have at least basic digital skills
        (EU 54%) and 65% have at least basic digital content creation skills (EU
        66%). ICT specialists make up 4.9% of the labour force (compared to the
        EU average of 4.5%), and 4.9% of all graduates (compared to the EU
        average of 3.5%) are ICT graduates. The proportion of female ICT
        specialists is equal to the EU average, at 19%. In 2020, 24% of German
        businesses provided specialised ICT training for their employees.
        Furthermore, several reforms are incorporated into the digital
        transition as part of Germany's recovery and resilience plan and,
        regarding Digital skills, the plan includes investments in four
        components: providing teachers with digital devices; creating a national
        skills platform; creating educational competence centres; and
        modernising educational facilities of the national military. Investments
        relating to the Digitalisation of enterprises and the development and
        integration of digital technologies are also included in several
        <CustomTooltip
          title={
            <div>
              Digital Skills & Jobs Platform, Germany: a snapshot of digital
              skills, 21/6/2023,{" "}
              <CountryLink
                link={
                  "https://digital-skills-jobs.europa.eu/en/latest/briefs/germany-snapshot-digital-skills"
                }
              />
            </div>
          }
        >
          <span>
            measures.<sup>(7)</sup>
          </span>
        </CustomTooltip>
      </p>
      <p>
        Whatsapp is the leading actively used service in Germany the past few
        years, were almost 84% of users confirmed this statement. Although
        WhatsApp is mainly a messaging service, certain features indicate
        similarities with social media networks, as sharing and posting between
        users still occurs, just not necessarily on a publicly accessible
        website. Facebook (61%) and Instagram (57.3%) are the next most popular
        platforms for the{" "}
        <CustomTooltip
          title={
            <div>
              Statista, Leading active social media and messaging platforms in
              Germany in 2022,{" "}
              <CountryLink
                link={
                  "https://www.statista.com/statistics/867539/top-active-social-media-platforms-in-germany/"
                }
              />
            </div>
          }
        >
          <span>
            Germans<sup>(8)</sup>
          </span>
        </CustomTooltip>
        .
      </p>
      <p>
        Furthermore, regarding the matter of influence of online actions in the
        following case on twitter (now known as X), what has been reported for a
        particular German far-right party, Alternative für Deutschland (AfD), is
        its digital activism using the hashjacking strategy (hijacking a
        hashtag, defined as using someone else’s hashtag to promote one’s own
        social media{" "}
        <CustomTooltip
          title={
            <div>
              Darius, Philipp & Stephany, Fabian. (2019). “Hashjacking” the
              Debate: Polarisation Strategies of Germany’s Political Far-Right
              on Twitter. 10.1007/978-3-030-34971-4_21.
            </div>
          }
        >
          <span>
            activity<sup>(9)</sup>
          </span>
        </CustomTooltip>{" "}
        ). Hashtags were designed and utilised to create a “virtual community of
        interested listeners” when directing users to a particular topic. As
        reported by{" "}
        <CustomTooltip
          title={
            <div>
              Sena Eksi (2022), Digital Populism: The Internet and the Rise of
              Right-wing Populism, ECPS. Available at:{" "}
              <CountryLink
                link={
                  "https://www.populismstudies.org/digital-populism-the-internet-and-the-rise-of-right-wing-populism/."
                }
              />
            </div>
          }
        >
          <span>
            Eksi<sup>(10)</sup>
          </span>
        </CustomTooltip>{" "}
        , in the “research by the Alexander von Humboldt Institute for Internet
        and Society showed that the Far-right AfD supporters hijacked rising
        hashtags in 2020, including #FlattenTheCurve or #CoronaVirusDE (Fox,
        2020). Most of the right-wing politicians use both their own party
        hashtags as well as the hashjacking method to strategically target
        opponent campaigns and to effectively polarize political discourse. As a
        result of their digital political communication strategy, they succeed
        not only online but also in elections{" "}
        <CustomTooltip
          title={
            <div>
              Darius, Philipp & Stephany, Fabian. (2019). “Hashjacking” the
              Debate: Polarisation Strategies of Germany’s Political Far-Right
              on Twitter. 10.1007/978-3-030-34971-4_21. <br />
              Sena Eksi (2022), Digital Populism: The Internet and the Rise of
              Right-wing Populism, ECPS. Available at:
              <CountryLink
                link={
                  "https://www.populismstudies.org/digital-populism-the-internet-and-the-rise-of-right-wing-populism/."
                }
              />
            </div>
          }
        >
          <span>
            (Darius & Stephany, 2019 )”<sup>(11, 12)</sup>
          </span>
        </CustomTooltip>
        . Οne can find supporting evidence to assert that parties, movements and
        the like, can organise and mobilise people through social media
        channels. Nonetheless, as analysed in a similar relevant article,
        “disentangling the causal relation between online activities and the
        public sphere is notoriously{" "}
        <CustomTooltip
          title={
            <div>
              {" "}
              Schwemmer, C. (2021). The Limited Influence of Right-Wing
              Movements on Social Media User Engagement. Social Media + Society,
              7(3).{" "}
              <CountryLink
                link={"https://doi.org/10.1177/20563051211041650"}
              />{" "}
              , p.10.
            </div>
          }
        >
          <span>
            difficult”<sup>(13)</sup>
          </span>
        </CustomTooltip>
        , what could be observed however is a “possible reinforcement process”
        among similar online communication strategies and “reactions of the
        audience: more radical posts lead to more user reactions and more
        reactions might eventually lead to more radicalized posts by the
        movement.”{" "}
      </p>
      <p>
        From the data presented in the document for the Country Profiles some
        key takeaways are:
      </p>
      <ul>
        <li>
          <strong>In terms of daily media consumption</strong>, TV remains
          predominant (70%), and is followed by radio (58%), online news (42%),
          social networks (38%), written press (35%) and TV via internet (23%).
          Interestingly, 33% of the population has no access to social{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              networks.<sup>(14)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Ages 15-24 more often use online social networks (89%) at least
              once a week and read news on the internet (77%) at least once a
              week, while only 33% read the written press at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(15)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 25-39 prefer to read news on the internet (86%) at least once
              a week and watch television on a TV set (79%) at least once a
              week, followed by similar use of online social networks (75%) at
              least once a week; 50% read the written press at least once a
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(16)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 40-54 present high preferences in watching television on a TV
              set (92%) consumed at least once a week; this is followed by
              listening to the radio (84%) at least once a week, reading news
              online (81%) at least once a week and using social networks (70%)
              at least once a week; written press is again the lowest in their
              preference (59%) consumed at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(17)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 55+ also have a clear preference in watching television on a
              TV set (95%) at least once a week, while their second preference
              is the radio (84%) consumed at least once a week; written press
              holds the highest percentage in this age group (80%) consumed at
              least once a week; online news (45%) and social networks appear to
              be the less popular mean (32%) consumed at least once a{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  week.<sup>(18)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the use of social media platforms in particular:{" "}
          </strong>
          <ul>
            <li>
              ages 15-24 mostly use Whatsapp (88%), equally Youtube and
              Instagram (80%), followed by Snapchat{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (53%)<sup>(19)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 also prefer Whatsapp (80%), followed by Youtube (66%)
              and Facebook{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (63%)<sup>(20)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 again prefer Whatsapp (78%), followed by Facebook (57%)
              and Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (51%)<sup>(21)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ present the least use of social media, where Whatsapp is
              preferred by 67%, Facebook by 48% and Youtube{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (35%)<sup>(22)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to accessing news</strong>, 73% of the population
          shows a clear preference for TV, while Radio is preferred by 47% and
          online news platforms by 38%. Podcasts remain the least popular mean
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              (6%).<sup>(23)</sup>
            </span>
          </CustomTooltip>{" "}
          In particular:
          <ul>
            <li>
              ages 15-24 prefer TV (46%) Youtube/other video platforms (40%) and
              social media platforms{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (49%)<sup>(24)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 25-39 again prefer the TV (59%), while online news platforms
              (48%) and the radio (44%) are{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  next<sup>(25)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 40-54 also have a clear preference for TV (74%), while some
              use the radio (50%) and online news platforms{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (42%)<sup>(26)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              ages 55+ similarly have a clear preference for TV (87%) and the
              radio (48%) and some for the written press{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (34%).<sup>(27)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>As regards to trust in news sources</strong>, interestingly
          the public TV and radio stations, as well as the written press
          (including online presence), seem to be the most trusted means across
          age{" "}
          <CustomTooltip
            title={
              <div>
                European Parliament (2022). ,Flash Eurobarometer FL011EP: Media
                & News Survey‘. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              groups.<sup>(28)</sup>
            </span>
          </CustomTooltip>
          <ul>
            <li>
              Public TV/Radio is trusted by 70% (highest) in ages 55+ and 53%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  25-39<sup>(29)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Public TV/Radio is trusted by 70% (highest) in ages 55+ and 53%
              (lowest) in ages{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  25-39<sup>(30)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Other online news platforms (podcasts etc) and influencers seem to
              enjoy the least trust by all age groups, ranging from 1% to{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  13%.<sup>(31)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Ages 15-24 however show some trust also in Youtube/video platforms
              (17%) and people/groups followed on social media/messaging
              platforms{" "}
              <CustomTooltip
                title={
                  <div>
                    European Parliament (2022). ,Flash Eurobarometer FL011EP:
                    Media & News Survey‘. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2832_fl011ep_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (16%).<sup>(32)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            As regards to the acknowledgment of D&FN and their existence within
            the country
          </strong>
          , 34% strongly consider it as an actual issue for Germany, 38% tend to
          believe it is an issue, while 20% in total either tend to, or totally
          disagree that this constitutes an{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              issue.<sup>(33)</sup>
            </span>
          </CustomTooltip>{" "}
          Furthermore, 57% of Germans agree/or tend to agree that they often
          come across D&FN, while 9% totally disagree with that{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              statement.<sup>(34)</sup>
            </span>
          </CustomTooltip>{" "}
          Moreover, 65% feel or tend to feel confident in identifying D&FN,
          while 29% feel less or no confidence at all in this{" "}
          <CustomTooltip
            title={
              <div>
                European Union (2022). ,Standard Eurobarometer STD96: Standard
                Eurobarometer 96 - Winter 2021-2022’. Available at:
                <CountryLink
                  link={
                    "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                  }
                />
              </div>
            }
          >
            <span>
              regard.<sup>(35)</sup>
            </span>
          </CustomTooltip>{" "}
          In terms of confidence,
          <ul>
            <li>
              Considering the respondents age groups, ages 15-24 (71%) and ages
              40-54 (72%) feel equally confident, while ages 55+ are the least
              confident{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  (58%)<sup>(36)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Considering the respondents' level of education, people that are
              still studying are the most confident (75%), ages 20+ feel
              slightly less confident (73%), while ages 15-19 range from 49% to
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  60%.<sup>(37)</sup>
                </span>
              </CustomTooltip>
            </li>
            <li>
              Considering the respondents’ placement in the political spectrum,
              interestingly people placing themselves on the right (69%) and the
              left (71%) feel almost equally confident from all positions, while
              people in the centre (62%) are slightly less{" "}
              <CustomTooltip
                title={
                  <div>
                    European Union (2022). ,Standard Eurobarometer STD96:
                    Standard Eurobarometer 96 - Winter 2021-2022’. Available at:
                    <CountryLink
                      link={
                        "https://data.europa.eu/data/datasets/s2553_96_3_std96_eng?locale=en."
                      }
                    />
                  </div>
                }
              >
                <span>
                  confident.<sup>(38)</sup>
                </span>
              </CustomTooltip>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
