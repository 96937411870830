import React, { useEffect } from "react";
import * as echarts from "echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/brush";

function SentimentGraph({ nodes }) {
  useEffect(() => {
    const nodesPerDay = {};
    nodes.forEach((node) => {
      let date = node.properties.tweet_created_at.split("T")[0];
      if (!nodesPerDay[date]) {
        nodesPerDay[date] = [];
      }

      nodesPerDay[date].push(node);
    });

    let sentimentData = [];
    Object.keys(nodesPerDay).forEach((key) => {
      sentimentData.push({ date: key, data: nodesPerDay[key] });
    });

    sentimentData = sortByDate(sentimentData);

    var chartDom = document.getElementById("echarts-container");
    var myChart = echarts.init(chartDom);
    var option;

    let xAxisData = [];
    let positiveScores = [];
    let negativeScores = [];
    let neutralScores = [];
    let unknownScores = [];
    sentimentData.forEach((item) => {
      xAxisData.push(item.date);
      positiveScores.push(
        item.data.filter(
          (node) => node.properties.prediction_label === "positive"
        ).length
      );
      negativeScores.push(
        item.data.filter(
          (node) => node.properties.prediction_label === "negative"
        ).length
      );
      neutralScores.push(
        item.data.filter(
          (node) => node.properties.prediction_label === "neutral"
        ).length
      );
      unknownScores.push(
        item.data.filter(
          (node) =>
            !["positive", "negative", "neutral"].includes(
              node.properties.prediction_label
            )
        ).length
      );
    });

    var emphasisStyle = {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: "rgba(0,0,0,0.3)",
      },
    };
    option = {
      legend: {
        data: ["Positive", "Neutral", "Negative", "Unknown"],
        right: "0%",
      },
      color: ["#91CC75", "#B5B5B5", "#E66", "#EBEBEB"],
      brush: {
        show: false,
        toolbox: [],
        xAxisIndex: 0,
      },
      toolbox: {
        show: false,
        feature: {
          magicType: {
            type: ["stack"],
          },
          dataView: {},
        },
      },
      tooltip: {},
      xAxis: {
        data: xAxisData,
        name: "Time",
        axisLine: { onZero: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        name: "No. of tweets",
      },
      grid: {
        bottom: 100,
      },
      series: [
        {
          name: "Positive",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: positiveScores,
        },
        {
          name: "Neutral",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: neutralScores,
        },
        {
          name: "Negative",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: negativeScores,
        },
        {
          name: "Unknown",
          type: "bar",
          stack: "one",
          itemStyle: {
            decal: {
              symbol: "rect",
              dashArrayX: [
                [3, 0],
                [0, 3, 0, 3],
              ],
              dashArrayY: [3, 0],
              rotation: -Math.PI / 4,
            },
          },
          emphasis: emphasisStyle,
          data: unknownScores,
        },
      ],
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 100,
          end: 0,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 100,
          end: 0,
        },
      ],
    };

    // Set the options and render the chart
    myChart.setOption(option);
  }, [nodes]);

  const sortByDate = (data) => {
    const sorted = [...data].sort((a, b) => {
      let dateStringA = a.date;
      let dateStringB = b.date;
      const dateObjA = new Date(dateStringA);
      const dateObjB = new Date(dateStringB);
      const compareResult = dateObjA - dateObjB;
      return compareResult;
    });

    return sorted;
  };

  return (
    <div id="echarts-container" style={{ width: "100%", height: "400px" }} />
  );
}

export default SentimentGraph;
