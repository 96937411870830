import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00556F",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "none",
            padding:"8px 24px",
            borderRadius: "8px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none"
            }
        },
        text: {
            "&:hover": {
                backgroundColor: "transparent"
              }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: "18px",
          fontWeight:"500",
          color: "#181818"
        }
      }
    },
  },
});

export default theme;
