import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../styles/CreateNewInvestigation.scss";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MultiSelect from "../../components/FormElements/MultiSelect";
import { Link, useNavigate } from "react-router-dom";
import investigationService from "../../services/InvestigationService";
import { FermiContext } from "../../App";

function CreateNewInvestigation() {
  const navigate = useNavigate();
  const { userData } = useContext(FermiContext);
  const [formData, setFormData] = useState({
    title: "",
    use_case: "",
    tweet: "",
    users: [],
  });

  const [alert, setAlert] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers([userData]);
  }, [userData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      formData.title.replace(/\s/g, "") !== "" &&
      formData.use_case.replace(/\s/g, "") !== "" &&
      formData.tweet.replace(/\s/g, "") !== "" &&
      formData.users.length !== 0
    ) {
      setAlert(null);
      let tweet = formData.tweet;
      let lastIndex = formData.tweet.lastIndexOf("/");
      let tweetId = tweet.slice(lastIndex + 1);
      if (Number(tweetId)) {
        investigationService
          .createInvestigation({
            ...formData,
            tweet: tweetId,
            use_case: Number(formData.use_case),
            created_at: createInvestigationDate(),
          })
          .then((response) => {
            setAlert({
              type: "success",
              message: "Investigation was successfully submitted!",
            });
            setTimeout(function () {
              navigate("/");
            }, 2000);
          })
          .catch((error) => {
            setAlert({
              type: "error",
              message:
                "There was a problem submitting your Investigation data!",
            });
          });
      } else {
        setAlert({
          type: "error",
          message: "Please provide tweet URl taht contains tweet id!",
        });
      }
    } else {
      setAlert({
        type: "warning",
        message: "You should fill in all the necessary fields!",
      });
    }
  };

  const handleDiscard = (event) => {
    event.preventDefault();
    setFormData({
      title: "",
      use_case: "",
      tweet: "",
      users: [],
    });
    setAlert(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (["users"].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDeleteViewer = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      users: prevData.users.filter((item) => item !== value),
    }));
  };

  const createInvestigationDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <div id="new-investigation-wrapper">
      <form>
        <h1>Add Investigation</h1>
        <FormControl fullWidth>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
            placeholder="Provide here a title for the new investigation"
          />
        </FormControl>
        <FormControl>
          <FormLabel id="investigation-use_case">
            Select the investigation use case:
          </FormLabel>
          <RadioGroup
            aria-labelledby="investigation-use_case"
            name="use_case"
            onChange={handleChange}
          >
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="Political interference from extremists on the Far-right wing"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="Health crisis: riots and different forms of violence (COVID-19)"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Extremist propaganda socio-political destabilization and real terrorist attacks from the Far-left wing"
            />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Identified D&FN post"
            name="tweet"
            value={formData.tweet}
            onChange={handleChange}
            fullWidth
            required
            placeholder="Provide here the URL of the identified D&FN post (eg. https://twitter.com/kolowe/status/16825641454813994)"
          />
        </FormControl>
        <FormControl fullWidth>
          <MultiSelect
            id={"users"}
            label={"Investigation's Viewers"}
            items={users}
            selectedItems={formData.users}
            handleChange={handleChange}
            handleDelete={handleDeleteViewer}
          />
        </FormControl>

        {alert && <Alert severity={`${alert.type}`}>{alert.message}</Alert>}

        <FormControl>
          <div className="form-buttons">
            <Button
              variant="contained"
              onClick={handleDiscard}
              id="whiteButton"
            >
              <Link to="/">Cancel</Link>
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Create investigation
            </Button>
          </div>
        </FormControl>
      </form>
    </div>
  );
}

export default CreateNewInvestigation;
