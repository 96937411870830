import React from "react";
import { Tooltip } from "@mui/material";

function CustomTooltip({ title, children }) {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "white",
            color: "black",
            padding: "14px",
            boxShadow: "1px 2px 6px 0px rgba(0, 0, 0, 0.25)",
            fontSize: "12px",
          },
        },
      }}
      title={title}
    >
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;
