import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabsContent from "./TabsContent";
import { useNavigate } from "react-router-dom";
function CountryTabs({ activeCountry }) {
  const navigate = useNavigate();
  const countries = ["finland", "sweden", "france", "belgium", "germany"];
  const [selectedCountry, stSelectedCountry] = useState(
    countries.includes(activeCountry) ? activeCountry : "germany"
  );

  useEffect(() => {
    if (!countries.includes(activeCountry)) {
      navigate(`/country-profiles/germany`);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    stSelectedCountry(newValue);
    navigate(`/country-profiles/${newValue}`);
  };
  return (
    <div>
      <Tabs
        value={selectedCountry}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
      >
        {countries.map((country, index) => (
          <Tab value={country} label={country} key={index} />
        ))}
      </Tabs>
      <TabsContent country={selectedCountry} />
    </div>
  );
}

export default CountryTabs;
