import React, { useEffect, useRef, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import "../../styles/MapChart.scss";
import geo from "../../data/topojson.json";
import PopUp from "../PopUp/PopUp";
import MapChartCountryInformation from "./MapChartCountryInformation/MapChartCountryInformation";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

function MapChart({ data }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [highlightedCountries, setHighlightedCountries] = useState([]);
  const [openCountryPopUp, setCountryPopUp] = useState(false);
  const [zoom, setZoom] = useState(2);

  useEffect(() => {
    if (data.country && data.country.length > 0) {
      let countryArray = [];
      data.country.forEach((element) => {
        countryArray.push(element.Country_name);
      });
      setHighlightedCountries(countryArray);
    }
  }, [data.country]);

  // const handleCountryHover = (geography) => {
  //   console.log(geography.properties)
  // };

  // const handleCountryLeave = () => {
  // };

  const handleCountryClick = (geography) => {
    if (highlightedCountries.includes(geography.properties.NAME)) {
      setSelectedCountry(geography.properties.NAME);
      setCountryPopUp(true);
    }
  };

  const handleCountryListClick = (country) => {
    setSelectedCountry(country);
    setCountryPopUp(true);
  };

  const zoomableGroupRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault(); // Prevent the default scroll behavior
      event.stopPropagation(); // Stop event propagation
    };

    const zoomableGroup = zoomableGroupRef.current;
    zoomableGroup.addEventListener("wheel", handleWheel);

    return () => {
      zoomableGroup.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleZoomIn = () => {
    setZoom(zoom + 0.5);
  };

  const resetZoom = () => {
    setZoom(2);
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.5);
    }
  };

  return (
    <>
      <div className="map-guidelines">
        <p>
          Click on the highlighted countries to view crime predictions per
          region & period.
        </p>
      </div>
      <div className="map-container">
        {highlightedCountries.length > 0 && (
          <div id="countries-list">
            <div className="wrapper">
              <div>Countries List</div>

              <ul>
                {highlightedCountries.map((country, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleCountryListClick(country);
                    }}
                    onMouseEnter={() => setSelectedCountry(country)}
                    onMouseLeave={() => setSelectedCountry("")}
                  >
                    {country} <EastIcon fontSize="tiny" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div id="zoom-buttons">
          <Button onClick={handleZoomIn}>
            <ZoomInIcon />
          </Button>
          <Button onClick={resetZoom}>
            <CenterFocusWeakIcon />
          </Button>
          <Button onClick={handleZoomOut}>
            <ZoomOutIcon />
          </Button>
        </div>

        <ComposableMap
          width={400}
          height={200}
          style={{ width: "100%", height: "auto" }}
        >
          <ZoomableGroup ref={zoomableGroupRef} center={[0, 50]} zoom={zoom}>
            <Geographies geography={geo}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    // onMouseEnter={() => handleCountryHover(geo)}
                    // onMouseLeave={handleCountryLeave}
                    onClick={() => handleCountryClick(geo)}
                    className={`default-countries ${
                      highlightedCountries.includes(geo.properties.NAME)
                        ? "highlighted-country"
                        : ""
                    } ${
                      selectedCountry === geo.properties.NAME
                        ? "selected-country"
                        : ""
                    }`}
                    style={{
                      default: {
                        outline: "none",
                      },
                      hover: {
                        outline: "none",
                      },
                      pressed: {
                        outline: "none",
                      },
                    }}
                    data-tooltip-id={`tool-${geo.properties.name}`}
                  />
                ))
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <PopUp
          openPopUp={openCountryPopUp}
          setOpenPopUp={setCountryPopUp}
          title={`Country: ${selectedCountry}`}
          closeFunction={() => {
            setSelectedCountry("");
          }}
        >
          <MapChartCountryInformation data={data} country={selectedCountry} />
        </PopUp>
      </div>
    </>
  );
}

export default MapChart;
