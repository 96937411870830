import React, { useContext, useEffect, useState } from "react";
import "../../styles/NavigationBar.scss";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, ClickAwayListener, Paper, Popper } from "@mui/material";
import UserService from "../../services/UserService";
import { FermiContext } from "../../App";

const popperStyle = {
  zIndex: 9999,
  position: "relative",
};

const paperStyle = {
  padding: "16px 8px",
  backgroundColor: "#ffffff",
  position: "relative",
  minWidth: "200px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
};

const triangleStyle = {
  content: '""',
  position: "absolute",
  top: "-8px",
  left: "48%",
  transform: "translateX(-50%)",
  borderTop: "10px solid #fff",
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
};

const triangleRotate = {
  transform: "rotate(180deg)",
};

function NavigationBar() {
  const userData = useContext(FermiContext);

  const [user, setUser] = useState(userData.userData.name ?? "");

  useEffect(() => {
    setUser(userData.userData?.name ?? "");
  }, [userData]);

  const handleLogout = () => {
    UserService.doLogout();
  };

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div id="nav-bar-wrapper">
      <div className="nav-bar-container page-container">
        <div id="nav-main-part">
          <Link to="/">
            <img src="/fermi-logo.png" alt="Fermi Logo" id="logo" />
          </Link>
        </div>

        <div id="secondary-menu">
          <Button onClick={handleClick} style={{ paddingInline: 0 }}>
            <img
              src="/img/svg/general/userIcon.svg"
              alt="User Icon"
              id="user-icon"
            />
          </Button>

          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            style={popperStyle}
            onClose={onClose}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Paper style={paperStyle}>
                {![undefined, ""].includes(user) && <div>{user}</div>}
                <div style={{ ...triangleStyle, ...triangleRotate }}></div>
                <Button variant="text" style={{ padding: 0 }}>
                  {/* <Link to="/manual" id="manual-link">
                    Manual
                  </Link> */}
                </Button>
                <Button
                  variant="text"
                  style={{ padding: 0 }}
                  onClick={handleLogout}
                >
                  Logout
                  <LogoutIcon fontSize="mini" style={{ marginLeft: "4px" }} />
                </Button>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
