import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../styles/Recommendations.scss";

function Recommendations({ countermeasures = [] }) {
  return (
    <div className="recommendations-wrapper">
      <div className="countermeasures">
        {/* <p className="title"></p> */}
        <div className="accordion">
          {countermeasures.slice(0, 3).map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`accordion-${index}`}
                id={`accordion-${index}`}
              >
                <Typography>
                  <span className="acc-title">{item.title}</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Recommendations;
