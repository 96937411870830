import React from "react";

function CountryLink({ link }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="footnote-link">
      {" "}{link}
    </a>
  );
}

export default CountryLink;
