import React, { useEffect } from "react";
import "../../styles/CountryProfiles.scss";
import { useParams } from "react-router-dom";
import CountryTabs from "./CountryTabs/CountryTabs";
function CountryProfiles() {
  let { country } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="country-profiles">
      <h1>Country Profiles</h1>
      <CountryTabs activeCountry={country} />
    </div>
  );
}

export default CountryProfiles;
