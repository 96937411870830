import React, { useEffect, useState } from "react";
import ColorsTable from "../../../components/NodeGraph/NodeGraphDetails/ColorsTable";
import "../../../styles/TweetImpact.scss";

function TweetImpact({ impact = "" }) {
  const [impactLevel, setImpactLevel] = useState(null);
  const colors = [
    { colorCode: "#91CC75", details: "Very low" },
    { colorCode: "#CACC64", details: "Low" },
    { colorCode: "#F9C858", details: "Medium" },
    { colorCode: "#F5A369", details: "High" },
    { colorCode: "#EE6666", details: "Very high" },
  ];

  useEffect(() => {
    let level = null;
    let stringImpact = impact.toString();
    if (stringImpact === "1") {
      level = "Very low";
    } else if (stringImpact === "2") {
      level = "low";
    } else if (stringImpact === "3") {
      level = "medium";
    } else if (stringImpact === "4") {
      level = "high";
    } else if (stringImpact === "5") {
      level = "Very high";
    }
    setImpactLevel(level);
  }, [impact]);
  return (
    <div className="tweet-impact-wrapper">
      {impactLevel !== null && (
        <img
          src={`/img/svg/speed-sentiment/${impactLevel}.svg`}
          alt="impact speedometer"
        />
      )}
      <span className="title mister">TWEET IMPACT</span>
      <ColorsTable colors={colors} square={true} centered={true} />
    </div>
  );
}

export default TweetImpact;
