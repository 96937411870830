import keycloak from "./keycloak";

// Username storage as an example
// Upadate this if you need to store more user data
var userName = "";

// User token storage.
// Called by keycloak.init()
const tokenHandler = async () => {
  try {
    await keycloak.updateToken();

    localStorage.setItem(
      "kc_tok",
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      })
    );
    sessionStorage.setItem(
      "kc_tok",
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      })
    );

    userName = keycloak.tokenParsed.preferred_username;
  } catch {
    doLogout();
  }
};

// Call this on every logout action
function doLogout() {
  clearUserTokens();
  keycloak.logout();
}

// Clears tokens without redirecting
function clearUserTokens() {
  localStorage.removeItem("kc_tok");
  sessionStorage.removeItem("kc_tok");
}

// Checks user auth status
function isLoggedIn() {
  return keycloak.authenticated;
}

// Returns user token
function getToken() {
  return keycloak.token;
}

// Called by ReactKeycloakProvider
// Handle here failed auth attempts
const eventHandler = (event, error) => {
  console.log("onKeycloakEvent", event, error);
};

// Returns user name
// Modify if you store more data
const GetUserName = () => {
  return userName;
};

// QA TEST - expired token
keycloak.onTokenExpired = () => {
  keycloak
    .updateToken()
    .then(() => {
      sessionStorage.setItem(
        "kc_tok",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      );
    })
    .catch(() => {
      console.log("token expired, error updating token");
      keycloak.logout();
    });
};

// Export all methods
const UserService = {
  tokenHandler,
  doLogout,
  clearUserTokens,
  isLoggedIn,
  getToken,
  eventHandler,
  GetUserName,
};

export default UserService;
