import React, { useContext, useState } from "react";
import "../../../styles/SelectedInvestigation.scss";
import { Alert, Button } from "@mui/material";
import investigationService from "../../../services/InvestigationService";
import { FermiContext } from "../../../App";
import PopUp from "../../../components/PopUp/PopUp";
// import AutorenewIcon from "@mui/icons-material/Autorenew";

function SelectedInvestigation({ investigation, onRefresh }) {
  const { userData } = useContext(FermiContext);
  const [deleteWarningPopUp, setDeleteWarningPopUp] = useState(false);
  const [deleteResult, setDeleteResult] = useState(null);

  const handleDelete = () => {
    setDeleteWarningPopUp(true);
  };

  const onDelete = () => {
    investigationService
      .deleteInvestigation(userData.sub, investigation.investigationID)
      .then((response) => {
        setDeleteResult({
          type: "success",
          message: "Investigation was deleted successfully!",
        });
        setTimeout(function () {
          onRefresh();
        }, 2000);
      })
      .catch((error) => {
        setDeleteResult({
          type: "error",
          message: "Investigation deletion failed!",
        });
      });
  };

  const onCancelDelete = () => {
    setDeleteWarningPopUp(false);
    setDeleteResult(null);
  };

  const findTopicName = (useCase) => {
    if (useCase === 1) {
      return "Extremist propaganda socio-political destabilization and real terrorist attacks from the Far-left wing";
    } else if (useCase === 2) {
      return "Health crisis: riots and different forms of violence (COVID-19)";
    } else if (useCase === 3) {
      return "Political interference from extremists on the Far-right wing";
    } else {
      return "";
    }
  };

  const tranformDate = (created_at) => {
    let date = new Date(created_at);
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      return "N/A";
    }
  };

  return (
    <>
      {investigation ? (
        <div id="selected-investigation">
          <div id="information">
            <div className="items-wrapper">
              <div className="item">
                <span className="mister">Title</span>
                <p>{investigation.title}</p>
              </div>
              <div className="item">
                <span className="mister">Topic</span>
                <p>{findTopicName(investigation.use_case)}</p>
              </div>
            </div>
            {investigation.created_at && (
              <div className="items-wrapper">
                <div className="item">
                  <span className="mister">Created at</span>
                  <p>{tranformDate(investigation.created_at)}</p>
                </div>
                {/* <div className="item">
                <span className="mister">last updated</span>
                <div className="info-with-action">
                  <p>{investigation.lastUpdated ?? "N/A"} </p>
                        <button>
                    <AutorenewIcon fontSize="small" />
                  </button>
                </div>
              </div> */}
              </div>
            )}
          </div>
          <div id="actions">
            {/* <Button variant="contained">Export PDF</Button> */}
            <Button variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </div>
          <PopUp
            title={"Delete Investigation"}
            openPopUp={deleteWarningPopUp}
            setOpenPopUp={setDeleteWarningPopUp}
          >
            <p>
              Are you sure you want to delete the investigation? This action can
              not be undone!
            </p>
            {deleteResult && (
              <Alert severity={`${deleteResult.type}`}>
                {deleteResult.message}
              </Alert>
            )}
            <div id="delete-actions">
              <Button variant="outlined" onClick={onCancelDelete}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#ef4444" }}
                onClick={onDelete}
              >
                Delete
              </Button>
            </div>
          </PopUp>
        </div>
      ) : null}
    </>
  );
}

export default SelectedInvestigation;
